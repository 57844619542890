<template>
    <div class="fhf">
        <div class="import-stepper pl-10 pr-10 pt-5 pb-0" v-if="!isNew">
            <mercur-stepper :is-full="true" :active-step.sync="activeStep && activeStep.id" @update:activeStep="gotToStep">
                <mercur-step v-for="(step, key) in steps" :id="step.id" :title="step.title" :key="key"/>
            </mercur-stepper>
        </div>

        <router-view class="fhf" v-bind="mappedDetails"
                     @pushRoute="pushRoute"
                     @goToHome="goToHome"
                     @execute="execute"
                     @save="saveSupplierImportedProduct"
                     @publish="publishImportedProduct"
                     @getSample="listDataSample"
                     @get="getSupplierImportedProductDetails"
                     @appendOption="appendOptionToAttribute"
        ></router-view>
        <mercur-dialog :is-open.sync="errorPopup.show">
            <div slot="header">
                <h3 class="font-weight-normal">{{ errorPopup.title }}</h3>
            </div>
            <div slot="default">
                <p class="max-width--700">
                    {{ errorPopup.message }}
                </p>
            </div>
            <div slot="footer">
                <mercur-button class="btn text-uppercase" @click="errorPopup.show = false">close</mercur-button>
            </div>
        </mercur-dialog>
    </div>
</template>
<script>
import CONFIG from '@root/config'
import { getDeepProperty } from '@/components/utils/Utils'

export default {
    name: 'SupplierImportedProductView',
    props: {
        supplierProductImportedId: {
            default: null,
        },
    },
    data () {
        return {
            supplierProductImportedDetails: null,
            isSavingProduct: false,
            availableAttributeNames: null,
            attributes: null,
            isPublishing: false,
            supplierProductImportedDataSample: {},
            isLoading: false,
            isGettingImportedProductDetails: true,
            retryConfiguration: {
                timeout: 500,
                factor: 2,
                maxAttempts: 10,
                currentAttempt: 1,
            },
            errorPopup: {
                show: false,
                title: '',
                message: '',
            },
        }
    },
    computed: {
        steps () {
            return [
                { title: 'Edit', id: 'SupplierImportedProductEdit' },
                { title: 'Upload', id: 'SupplierImportedProductUpload' },
                { title: 'Columns', id: 'SupplierImportedProductCsvFileColumnMapping' },
                { title: 'Values', id: 'SupplierImportedProductCsvFileValueMapping' },
                { title: 'Facilities', id: 'SupplierImportedProductFacilities' },
                { title: 'Packages', id: 'SupplierImportedProductPackages' },
                { title: 'Cutoff', id: 'SupplierImportedProductCutoff' },
                { title: 'Artwork', id: 'SupplierImportedProductArtwork' },
                { title: 'Confirmation', id: 'SupplierImportedProductConfirmation' },
            ]
        },
        activeStep: {
            get () {
                return this.steps.find(step => step.id === this.$route.name)
            },
            set (value) {
                let stepId = this.steps[value] && this.steps[value].id
                if (stepId) {
                    this.$set(this.retryConfiguration, 'currentAttempt', 1)
                    this.pushRoute({ name: stepId })
                }
            },
        },
        breadcrumbElement () {
            return {
                name: getDeepProperty('supplierProductImportedDetails.productName', this, '...'),
            }
        },
        mappedDetails () {
            return {
                ...this.$data,
                supplierProductImportStatus: getDeepProperty('productImportStatus', this.supplierProductImportedDetails, null),
            }
        },
        currentSupplierId () {
            return this.$route.params.supplierId || (this.supplierProductImportedDetails && this.supplierProductImportedDetails.supplierId) || this.supplierId
        },
        currentSupplierProductImportedId () {
            const id = this.$route.params.supplierProductImportedId || (this.supplierProductImportedDetails && this.supplierProductImportedDetails.supplierProductImportedId) || this.supplierProductImportedId
            return id === 'new' ? null : id
        },
        isNew () {
            return !this.currentSupplierProductImportedId
        },
    },
    methods: {
        goToHome ({ supplierId }) {
            this.$router.push({
                name: 'SupplierImportedProducts',
                params: {
                    supplierId,
                },
            })
        },
        gotToStep (name) {
            this.pushRoute({ name })
        },
        async pushRoute ({ name, params, force, refreshData }) {
            if (!this.isLoading || force) {
                this.$router.push({
                    name: name,
                    params: {
                        ...this.$route.params,
                        ...(params || {}),
                    },
                })
                if (refreshData) {
                    this.supplierProductImportedDetails = {
                        ...(this.supplierProductImportedDetails || {}),
                        supplierId: params.supplierId,
                        supplierProductImportedId: params.supplierProductImportedId,
                    }
                    await this.getAndStoreProductDetails()
                }
            }
        },
        async execute ({ fn, errorMessage, onError, onSuccess }) {
            if (this.isLoading) {
                return
            }
            let result = null
            try {
                this.isLoading = true
                result = await fn()
            } catch (error) {
                onError && onError(error)
                console.error(error)
                const errors = error.data ? { data: error.data } : error
                this.$root.$emit('notification:global', {
                    message: errorMessage || error.message || null,
                    type: 'error',
                    errors: errors,
                })
                return
            } finally {
                this.isLoading = false
            }
            onSuccess && onSuccess(result)
        },
        async saveSupplierImportedProduct ($event, options = {}) {
            const onSuccess = $event.onSuccess || options.onSuccess || null
            delete $event.onSuccess
            const onError = $event.onError || options.onError || null
            delete $event.onError

            return this.execute(
                {
                    fn: async () => {
                        const url = CONFIG.API.ROUTES.SUPPLIERS.IMPORTED_PRODUCTS.UPDATE
                            .replace('{supplierId}', this.currentSupplierId)
                            .replace('{supplierProductImportedId}', this.currentSupplierProductImportedId)
                        try {
                            this.isSavingProduct = true
                            this.addJob(url)
                            await this.$api.post(url, {
                                supplierId: this.currentSupplierId,
                                supplierProductImportedId: this.currentSupplierProductImportedId,
                                ...$event,
                            })
                            if (!options.silent) {
                                this.$root.$emit('notification:global', {
                                    message: `Saving details completed.`,
                                })
                            }

                            await this.getAndStoreProductDetails()

                            if (options.pushRoute) {
                                await this.pushRoute({ ...options.pushRoute, force: true })
                            }
                        } finally {
                            this.finishJob(url)
                            this.isSavingProduct = false
                        }
                    },
                    errorMessage: `Saving details failed. Please try again`,
                    onError,
                    onSuccess,
                }
            )
        },
        async getSupplierImportedProductDetails () {
            return this.execute({
                fn: this.getAndStoreProductDetails,
                errorMessage: `Getting details for product failed. Please try again.`,
            })
        },
        async getAndStoreProductDetails () {
            if (this.isNew) {
                return
            }
            const url = CONFIG.API.ROUTES.SUPPLIERS.IMPORTED_PRODUCTS.GET
                .replace('{supplierId}', this.currentSupplierId)
                .replace('{supplierProductImportedId}', this.currentSupplierProductImportedId)
            try {
                this.addJob(url)
                this.isGettingImportedProductDetails = true
                const data = await this.$api.get(url)
                this.$set(this, 'supplierProductImportedDetails', data.data.data)
                if (this.supplierProductImportedDetails.productImportStatus === 'PROCESSING_FILE' && this.activeStep.id === 'SupplierImportedProductCsvFileColumnMapping' && this.retryConfiguration.currentAttempt < this.retryConfiguration.maxAttempts) {
                    const time = this.retryConfiguration.timeout * (this.retryConfiguration.currentAttempt ** this.retryConfiguration.factor)
                    setTimeout(() => {
                        this.getSupplierImportedProductDetails()
                        this.retryConfiguration.currentAttempt++
                    }, time)
                }
            } finally {
                this.finishJob(url)
                this.isGettingImportedProductDetails = false
            }
        },
        getAttributesAndOptions () {
            const mercurAttributesUrl = CONFIG.API.ROUTES.PRODUCTS.GENERATION.ATTRIBUTES.GET
            this.addJob(mercurAttributesUrl)
            return this.$api.get(mercurAttributesUrl).then(({ data }) => {
                this.$set(this, 'attributes', data.data)
                this.$set(this, 'availableAttributeNames', Object.keys(data.data))
            }).finally(() => {
                this.finishJob(mercurAttributesUrl)
            })
        },
        publishImportedProduct (payload) {
            const url = CONFIG.API.ROUTES.SUPPLIERS.IMPORTED_PRODUCTS.PUBLISH
                .replace('{supplierId}', this.currentSupplierId)
                .replace('{supplierProductImportedId}', this.currentSupplierProductImportedId)
            this.isPublishing = true

            this.saveSupplierImportedProduct(payload, {
                silent: true,
                onError: () => { this.isPublishing = false },
                onSuccess: () => {
                    this.$api.post(url, payload).then((res) => {
                        this.$root.$emit('notification:global', {
                            message: `Product was published`,
                            type: 'success',
                        })
                        this.goToHome({ supplierId: this.currentSupplierId })
                    }).catch((data) => {
                        this.getAndStoreProductDetails().then(() => {
                            if (this.supplierProductImportedDetails.productImportStatus === 'AWAITING_TEMPLATE_APPROVAL') {
                                this.errorPopup.title = 'Product awaiting template approval'
                                this.errorPopup.message = 'The product is still awaiting template approval and cannot be published right now.'
                                this.errorPopup.show = true
                            } else if (this.supplierProductImportedDetails.productImportStatus === 'AWAITING_APPROVAL') {
                                this.errorPopup.title = 'Product awaiting approval'
                                this.errorPopup.message = 'The product is still awaiting approval and cannot be published right now.'
                                this.errorPopup.show = true
                            } else {
                                this.$root.$emit('notification:global', {
                                    message: `Publishing product failed.`,
                                    type: 'error',
                                    errors: data,
                                })
                            }
                        })
                    }).finally(() => {
                        this.isPublishing = false
                    })
                } }
            )
        },
        listDataSample () {
            if (Object.keys(this.supplierProductImportedDataSample).length) {
                return
            }
            const url = CONFIG.API.ROUTES.SUPPLIERS.IMPORTED_PRODUCTS.LIST_DATA_SAMPLE
                .replace('{supplierId}', this.currentSupplierId)
                .replace('{supplierProductImportedId}', this.currentSupplierProductImportedId)
            this.$api.get(url).then(({ data }) => {
                this.supplierProductImportedDataSample = data.data.samples
            }).catch((err) => {
                console.error(err)
                this.$root.$emit('notification:global', {
                    message: `Getting data sample failed. Please try again.`,
                    type: 'error',
                    errors: err,
                })
            })
        },
        appendOptionToAttribute (option) {
            const clone = JSON.parse(JSON.stringify(this.attributes))
            clone[option.attributeName].push({
                additionalData: option.additionalData,
                attributeId: option.attributeId,
                configuration: option.configuration,
                option: option.attributeOption,
                postfix: option.postfix,
            })
            this.$set(this, 'attributes', clone)
        },
    },
    async created () {
        await Promise.all([this.getSupplierImportedProductDetails(), this.getAttributesAndOptions()])
    },
}
</script>

<style scoped lang="scss">

</style>
